import React from "react";
import { Link } from "gatsby";
import "./OtherCategoriesList.scss";

const OtherCategoriesList = (props) => {
  return (
    <ul
      className="other-categories-container"
      style={{
        marginTop: `${props.extraMargin ? "35px" : "0"}`,
        marginBottom: `${props.extraMargin ? "120px" : "0"}`,
      }}
    >
      {props.data.map((category, i) => {
        return (
          <li className="category-name" key={i}>
            <Link
              to={`/products/${category.categorySlug}`}
              title={category.categoryInnerTitle}
              className="link"
            >
              {category.categoryInnerTitle}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default OtherCategoriesList;
