import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout/Layout";
import OtherCategoriesList from "../../components/OtherCategoriesList/OtherCategoriesList";
import ProductDetails from "../../components/ProductDetails/ProductDetails";
import VideoGallery from "../../components/VideoGallery/VideoGallery";
import SocialMedia from "../../components/SocialMedia/SocialMedia";

import "./Category.scss";

export default function Category({ data }) {
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window.scrollY >= 300 && window.scrollY <= 6300) {
          setFixed(true);
        } else {
          setFixed(false);
        }
      });
    }
  }, []);

  const currentCategoryData = data.allContentfulCategory.edges[0].node;

  return (
    <Layout
      navData={
        data.allContentfulWebPages.edges[0].node.pages[0].pageLayout.navigation
      }
      image={currentCategoryData.categoryImage.file.url}
      footerData={
        data.allContentfulWebPages.edges[0].node.pages[0].pageLayout.footer
      }
      pageTitle={`${currentCategoryData.categoryInnerTitle} | Product Category`}
    >
      <div className="category-header">
        {currentCategoryData.categoryName.map((line, i) => {
          return (
            <h1 className="current-category-name uppercase" key={i}>
              {line}
            </h1>
          );
        })}
      </div>
      <div
        className={fixed ? "social-buttons fixed " : "social-buttons"}
        style={{ top: !fixed ? "200px" : "" }}
      >
        <SocialMedia
          socialMediaData={
            data.allContentfulWebPages.edges[0].node.pages[1].pageLayout
              .navigation.navigationPages[1].pageLayout.layoutMain[1]
              .buttonsContainer
          }
        />
      </div>
      <OtherCategoriesList data={currentCategoryData.otherCategories} />

      {/* Product teasers of this category */}
      {currentCategoryData.categoryProducts ? (
        currentCategoryData.categoryProducts.map((productTeaser, i) => {
          return <ProductDetails data={productTeaser} key={productTeaser.id} />;
        })
      ) : (
        <div className="category-placeholder">
          <h1>Coming Soon...</h1>
        </div>
      )}

      <OtherCategoriesList
        extraMargin={true}
        data={currentCategoryData.otherCategories}
      />
      {/* <VideoGallery data={currentCategoryData.categoryVideos} /> */}
    </Layout>
  );
}
export const query = graphql`
  query CategoryQuery($slug: String!) {
    allContentfulWebPages {
      edges {
        node {
          pages {
            pageLayout {
              navigation {
                navigationPages {
                  pageTitle
                  pageSlug
                  pageLayout {
                    layoutMain {
                      ... on ContentfulContainer {
                        containerItems {
                          ... on ContentfulCategoryTeaser {
                            category {
                              categoryInnerTitle
                              categorySlug
                              categoryProducts {
                                product {
                                  productInnerTitle
                                  productSlug
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on ContentfulButtonsContainer {
                        buttonsContainer {
                          socialMediaLink
                          socialMediaTitle
                        }
                      }
                    }
                  }
                }
                navigationImages {
                  file {
                    url
                  }
                  description
                }
                navigationMediaIcons {
                  socialMediaIcon {
                    file {
                      url
                    }
                    description
                  }
                  socialMediaTitle
                  socialMediaLink
                }
              }
              footer {
                subscribeText
                subscribeDescription
                footerButtonText
                blockTitleFollowUs
                socialMediaLogosFooter {
                  socialMediaIcon {
                    file {
                      url
                    }
                  }
                  socialMediaLink
                }
                followUsDescription
                blockTitleAddress
                blockInfoAddress
                blockTitleCallUs
                blockInfoCallUs
                blockTitleWriteUs
                blockInfoWriteUs
                blockTitleExplore
                footerPages {
                  pageTitle
                  pageSlug
                }
                copyrightText
                googleMapsAddress {
                  googleMapsAddress
                }
              }
            }
          }
        }
      }
    }
    allContentfulCategory(filter: { categorySlug: { eq: $slug } }) {
      edges {
        node {
          categoryInnerTitle
          categoryName
          categorySlug
          categoryImage {
            file {
              url
            }
          }
          otherCategories {
            categoryInnerTitle
            categorySlug
          }
          categoryProducts {
            id
            productTeaserName
            product {
              productInnerTitle
              productSlug
              productMainImage {
                description
                file {
                  url
                }
              }
            }
            productTeaserDescription {
              internal {
                content
              }
            }
            productTeaserCheckButton {
              buttonText
              buttonLink {
                ... on ContentfulProduct {
                  productSlug
                  productCategory {
                    categorySlug
                  }
                }
              }
            }
            productTeaserShareButton {
              buttonText
              buttonLink {
                ... on ContentfulProduct {
                  productSlug
                  productCategory {
                    categorySlug
                  }
                }
              }
            }
          }
          categoryVideos {
            videosContainerSubHeader
            videosContainerHeader
            videosContainerSwipeText
            videosContainerVideosList {
              id
              youTubeLinkTitle
              youTubeVideoUrl
            }
          }
        }
      }
    }
  }
`;
