import React, { useState } from "react";
import { Link } from "gatsby";
import TwoColumnsLayout from "../TwoColumnsLayout/TwoColumnsLayout";

import "./ProductDetails.scss";

const ProductDetails = (props) => {
  const [copySuccess, setCopySuccess] = useState(null);

  let rootLink = "";
if (typeof window !== "undefined") {
  rootLink = window.location.origin + "" + window.location.pathname;
}
  const copyToClipboard = () => {
    const link =
      rootLink + props.data.productTeaserCheckButton.buttonLink.productSlug;
    navigator.clipboard.writeText(link);
    setCopySuccess("Link copied!");
    setTimeout(() => {
      setCopySuccess(null);
    }, 2000);
  };

  const productDetails = {
    productDetailsLeft: (
      <div className="image">
        <img
          src={`https:${props.data.product.productMainImage?.file.url}`}
          alt={props.data.product.productMainImage?.description}
          loading="lazy"
        />
      </div>
    ),
    productDetailsRight: (
      <>
        <div className="title">{props.data.productTeaserName}</div>
        <div className="description">
          <p>{props.data.productTeaserDescription.internal.content}</p>
        </div>
        <div className="buttons container-flex container-flex--align-center">
          <div className="btn">
            <Link
              to={`/products/${props.data.productTeaserCheckButton.buttonLink.productCategory.categorySlug}/${props.data.productTeaserCheckButton.buttonLink.productSlug}`}
              className="btn-primary"
            >
              {props.data.productTeaserCheckButton.buttonText}
            </Link>
          </div>
          <div className="quaternary" onClick={copyToClipboard}>
            {copySuccess
              ? copySuccess
              : props.data.productTeaserShareButton.buttonText}
          </div>
        </div>
      </>
    ),
  };

  return (
    <div className="productDetails">
      <TwoColumnsLayout
        leftContent={productDetails.productDetailsLeft}
        rightContent={productDetails.productDetailsRight}
      />
    </div>
  );
};

export default ProductDetails;
